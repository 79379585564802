import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
// redux
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  Button,
  Spinner,
  Icon,
  Alert,
} from "@amzn/awsui-components-react/polaris";

import constants, { DEFAULT_DROPDOWN_LIMIT } from "../../../config/constants";
// child components
import BreadcrumbGroup from "../../../components/breadcrumb-group";
// sub components
import RVRDropdownList from "./dropdown/dropdown-list";
import RvRRangeModal from "./range-calculator/range-calculator-modal";
import RvRVisualization from "../../visualization/rvr/rvr-visualization";
// redux action
import {
  setUserSelectDict,
  initRVRLoadingStatus,
} from "../../../redux/actions/rvr-config-action";
import querySearch from "query-string";
import styled from "styled-components";

const StyledButtonDiv = styled.div`
  padding-left: 32px;
`;

interface StateProps {
  rvrConfigReducer: any;
}

interface MatchParams {
  project: string;
  build: string;
  version: string;
  test_category: string;
}

interface QueryString {
  request_id?: number;
  lab_id?: number;
}

// declare prop check
type Props = {
  dispatch: Dispatch<any>;
} & typeof defaultProps &
  RouteComponentProps<MatchParams> &
  StateProps;

// declare init state & default props
const defaultProps = Object.freeze({});
const initialState = Object.freeze({
  notShortDropdown: true,
  showModal: false,
  userTestCase: [1] as number[],
  requestId: undefined,
  labId: undefined,
});

class RVRTestcase extends Component<Props> {
  readonly state = initialState;
  private plotRef: React.RefObject<HTMLInputElement>;

  constructor(props) {
    super(props);
    this.plotRef = React.createRef();
  }

  onClickAddDropdown = () => {
    const { selectedData } = this.props.rvrConfigReducer;

    const currentTestCastObject = { ...selectedData };
    const newKeyNumber =
      Math.max(
        ...Object.keys(currentTestCastObject).map((key) => parseInt(key))
      ) + 1;
    currentTestCastObject[newKeyNumber] = {};

    this.props.dispatch(setUserSelectDict(currentTestCastObject));
  };
  onClickClear = () => {
    const { project, build, version } = this.props.match.params;

    // completed clean the object, remove states in dropdownlist
    const cleanSelectedData = {};
    this.props.dispatch(setUserSelectDict(cleanSelectedData));
    // init object, ask re-render component dropdownlist
    const initSelectedData = {
      0: {
        project: project,
        build: build,
        version: version,
      },
    };
    this.props.dispatch(setUserSelectDict(initSelectedData));
    this.props.dispatch(initRVRLoadingStatus());
  };

  componentWillUnmount() {
    const cleanSelectedData = {};
    this.props.dispatch(setUserSelectDict(cleanSelectedData));
    // init object, ask re-render component dropdownlist
    const initSelectedData = {
      0: {},
    };
    this.props.dispatch(setUserSelectDict(initSelectedData));
    this.props.dispatch(initRVRLoadingStatus());
  }

  _showModal = () => {
    this.setState({
      showModal: true,
    });
  };

  _closeModal = () => {
    this.setState({
      showModal: false,
    });
  };

  handleDownload = () => {
    const { downloadable_links } = this.props.rvrConfigReducer;
    downloadable_links.forEach((url,index) => {
      setTimeout(() => {
        const link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        link.click();  
        link.remove(); 
        index += 2
      }, index * 1000); 
    });   
  };

  render() {
    const {
      tcsResultLoadingStatus,
      tcsResultMessage
    } = this.props.rvrConfigReducer;
    const { project, build, version } = this.props.match.params;
    const { showModal } = this.state;
    const { selectedData } = this.props.rvrConfigReducer;

    let queryString: QueryString = {};
    if (this.props.location && this.props.location.search) {
      queryString = querySearch.parse(this.props.location.search);
    }
    const requestId = queryString.request_id
      ? queryString.request_id
      : undefined;
    const labId = queryString.lab_id ? queryString.lab_id : undefined;

    const stopAddNew =
      Object.keys(selectedData).length >= DEFAULT_DROPDOWN_LIMIT ? true : false;

    const mapMultiDropdown = Object.keys(selectedData).map((eachKey, index) => (
      <div key={index}>
        Testcase {eachKey}:
        <RVRDropdownList
          requestId={requestId}
          labId={labId}
          indexNumber={eachKey}
          project={project}
          build={build}
          version={version}
        />
      </div>
    ));

    return (
      <div>
        <BreadcrumbGroup
          textGroup={[
            { text: "Dashboard", href: "/" },
            { text: `RvR View` }, // TestCategory
          ]}
        />
        <br />

        {mapMultiDropdown}
        <br />
        <div className="Btn-left-bottom">
          {constants.LOADING_LOAD != tcsResultLoadingStatus && (
            <>
              <Button onClick={this.onClickClear}>Clear</Button>
              <Button disabled={stopAddNew} onClick={this.onClickAddDropdown}>
                Add
              </Button>
            </>
          )}
          {constants.LOADING_SUCCESS === tcsResultLoadingStatus && (
            <>
              <StyledButtonDiv className="Btn-right-bottom float-right">
                  <Button className="right-button"
                    onClick={this.handleDownload}
                  >
                    Download Test Cases
                  </Button>
              </StyledButtonDiv>
              <div className="Btn-right-bottom float-right">
                <Button
                  onClick={this._showModal}
                >
                  Range Constants
                </Button>
              </div>
            </>
          )}
        </div>
        <br />
        {constants.LOADING_BEFORE_START === tcsResultLoadingStatus && (
          <div className="desense-notice-info">
            Click submit & load the visualization
          </div>
        )}
        {constants.LOADING_LOAD === tcsResultLoadingStatus && (
          <div>
            <Spinner size="large" />
          </div>
        )}
        {constants.LOADING_SUCCESS === tcsResultLoadingStatus && (
          <div>
            {tcsResultMessage.includes("not") ? (
              <div>
                <Icon name="status-negative" size="normal" variant="error" />
                {tcsResultMessage}
              </div>
            ) : (
              <div>
                <RvRRangeModal
                  showModal={showModal}
                  onDismiss={this._closeModal}
                  onClose={this._closeModal}
                />
                <RvRVisualization />
              </div>
            )}
          </div>
        )}
        {constants.LOADING_FAIL === tcsResultLoadingStatus && (
          <div>
            <Alert type="error" header="Fetch data failed">
              Loading Failed. {tcsResultMessage}
            </Alert>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    rvrConfigReducer: state.rvrConfigReducer,
  };
};
export default withRouter(connect<StateProps>(mapStateToProps)(RVRTestcase));
